import refreshToken from '@/plugins/refresh-token'
import { api } from '../main'

const settingUpdate = async body => {
  ////await refreshToken()
  const response = await api
    .put({
      path: 'systemsettings',
      body,
    })
    .then(res => (res || {}))
    .catch(err => {
      console.log('update shop setting : ', err)

      return {}
    })

  return response
}
const settingImageUpload = async body => {
  ////await refreshToken()
  const response = await api
    .post({
      path: 'systemsettings/gallery',
      body,
    })
    .then(res => (res || {}))
    .catch(err => {
      console.log('update image setting : ', err)

      return {}
    })

  return response
}
const settingImageRemove = async id => {
  ////await refreshToken()
  const response = await api
    .delete({
      path: 'systemsettings/gallery',
      param: id,
    })
    .then(res => (res || {}))
    .catch(err => {
      console.log('delete image setting : ', err)

      return {}
    })

  return response
}
export default { settingUpdate, settingImageUpload, settingImageRemove }
