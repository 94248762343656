<template>
  <div>
    <v-card :loading="loading">
      <v-card-title>
        {{ $t('System settings') }}
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col
            cols="12"
            lg="4"
            class="pt-0 text-center"
          >
            <img
              :src="shopData.shop_image"
              width="200px"
              alt=""
            />
          </v-col>
          <v-col
            cols="12"
            lg="8"
            class="pt-0"
          >
            <v-text-field
              v-model.trim="shopData.shop_id"

              outlined
              dense
              :label="$t('shop_code')"
              readonly
            ></v-text-field>
            <v-text-field
              v-model.trim="shopData.shop_name"
              outlined
              dense
              :label="$t('shop_name')"
              readonly
            ></v-text-field>
            <v-text-field
              v-model.trim="shopData.shop_nature"
              outlined
              dense
              :label="$t('nature_service')"
              readonly
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            cols="12"
            lg="6"
          >
            <v-select
              v-model.trim="bankSelect"
              :items="bankData"
              :label="$t('bank_icon')"
              dense
              outlined
              item-text="bank_name"
              return-object
              item-value="bank_icon_id"
            >
              <template v-slot:append-outer>
                <i
                  :class="bankSelect.bank_icon"
                ></i>
              </template>
            </v-select>
            <v-text-field
              v-model.trim="bankSelect.bank_name"
              outlined
              dense
              :label="$t('bank')"
            ></v-text-field>
            <v-text-field
              v-model.trim="shopData.bank_branch"
              outlined
              dense
              :label="$t('branch')"
            ></v-text-field>
            <v-text-field
              v-model.trim="shopData.bank_account_number"
              outlined
              dense
              :label="$t('account_number')"
            ></v-text-field>
            <v-text-field
              v-model.trim="shopData.bank_account_name"
              outlined
              dense
              :label="$t('account_name')"
            ></v-text-field>
          </v-col>
          <v-col
            cols="12"
            lg="6"
          >
            <div class="setfontTitle">
              {{ $t("detail") }}
            </div>
            <quill-editor
              ref="myTextEditor"
              v-model="shopDetail"
              :config="{}"
              class="pb-2"
            >
            </quill-editor>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          :loading="loading"
          :disabled="loading"
          @click="updateShop"
        >
          {{ $t('save') }}
        </v-btn>
        <v-btn
          color="secondary"
          :loading="loading"
          :disabled="loading"
          outlined
          @click="getShop"
        >
          {{ $t('default') }}
        </v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
    <v-card
      class="mt-5"
      :loading="loading"
    >
      <v-card-title>
        {{ $t("gallery") }}
        <span class="error--text">
          {{ $t("NoteGallery480640") }}
        </span>
      </v-card-title>
      <input
        ref="selectImage"
        type="file"
        style="display: none"
        @change="previewFiles"
      />
      <v-card-text>
        <v-row
          align="center"
          justify="center"
        >
          <v-col
            cols="12"
            md="6"
            lg="4"
          >
            <v-card>
              <v-img
                :src="shopData.shop_image1"
              ></v-img>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      color="primary"
                      class="ma-2 white--text"
                      v-bind="attrs"
                      x-small
                      fab
                      v-on="on"
                      @click="showSelectImage(1)"
                    >
                      <v-icon dark>
                        {{ icons.mdiCloudUploadOutline }}
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t("upload_pictures") }}</span>
                </v-tooltip>

                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      color="error"
                      class="ma-2 white--text"
                      v-bind="attrs"
                      x-small
                      fab
                      v-on="on"
                      @click="deleteImage(1)"
                    >
                      <v-icon dark>
                        {{ icons.mdiDeleteOutline }}
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t("DeleteImage") }}</span>
                </v-tooltip>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-col>
          <v-col
            cols="12"
            md="6"
            lg="4"
          >
            <v-card>
              <v-img
                :src="shopData.shop_image2"
                alt=""
              ></v-img>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      color="primary"
                      class="ma-2 white--text"
                      v-bind="attrs"
                      x-small
                      fab
                      v-on="on"
                      @click="showSelectImage(2)"
                    >
                      <v-icon dark>
                        {{ icons.mdiCloudUploadOutline }}
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t("upload_pictures") }}</span>
                </v-tooltip>

                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      color="error"
                      class="ma-2 white--text"
                      v-bind="attrs"
                      x-small
                      fab
                      @click="deleteImage(2)"
                      v-on="on"
                    >
                      <v-icon dark>
                        {{ icons.mdiDeleteOutline }}
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t("DeleteImage") }}</span>
                </v-tooltip>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-col>
          <v-col
            cols="12"
            md="6"
            lg="4"
          >
            <v-card>
              <v-img
                :src="shopData.shop_image3"
              ></v-img>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      color="primary"
                      class="ma-2 white--text"
                      v-bind="attrs"
                      x-small
                      fab
                      v-on="on"
                      @click="showSelectImage(3)"
                    >
                      <v-icon dark>
                        {{ icons.mdiCloudUploadOutline }}
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t("upload_pictures") }}</span>
                </v-tooltip>

                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      color="error"
                      class="ma-2 white--text"
                      v-bind="attrs"
                      x-small
                      fab
                      @click="deleteImage(3)"
                      v-on="on"
                    >
                      <v-icon dark>
                        {{ icons.mdiDeleteOutline }}
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t("DeleteImage") }}</span>
                </v-tooltip>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-col>
          <v-col
            cols="12"
            md="6"
            lg="4"
          >
            <v-card>
              <v-img
                :src="shopData.shop_image4"
                alt=""
              ></v-img>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      color="primary"
                      class="ma-2 white--text"
                      v-bind="attrs"
                      x-small
                      fab
                      v-on="on"
                      @click="showSelectImage(4)"
                    >
                      <v-icon dark>
                        {{ icons.mdiCloudUploadOutline }}
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t("upload_pictures") }}</span>
                </v-tooltip>

                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      color="error"
                      class="ma-2 white--text"
                      v-bind="attrs"
                      x-small
                      fab
                      @click="deleteImage(4)"
                      v-on="on"
                    >
                      <v-icon dark>
                        {{ icons.mdiDeleteOutline }}
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t("DeleteImage") }}</span>
                </v-tooltip>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-col>
          <v-col
            cols="12"
            md="6"
            lg="4"
          >
            <v-card>
              <v-img
                :src="shopData.shop_image5"
              ></v-img>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      color="primary"
                      class="ma-2 white--text"
                      v-bind="attrs"
                      x-small
                      fab
                      v-on="on"
                      @click="showSelectImage(5)"
                    >
                      <v-icon dark>
                        {{ icons.mdiCloudUploadOutline }}
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t("upload_pictures") }}</span>
                </v-tooltip>

                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      color="error"
                      class="ma-2 white--text"
                      v-bind="attrs"
                      x-small
                      fab
                      @click="deleteImage(5)"
                      v-on="on"
                    >
                      <v-icon dark>
                        {{ icons.mdiDeleteOutline }}
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t("DeleteImage") }}</span>
                </v-tooltip>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-col>
          <v-col
            cols="12"
            md="6"
            lg="4"
          >
            <v-card>
              <v-img
                :src="shopData.shop_image6"
              ></v-img>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      color="primary"
                      class="ma-2 white--text"
                      v-bind="attrs"
                      x-small
                      fab
                      v-on="on"
                      @click="showSelectImage(6)"
                    >
                      <v-icon dark>
                        {{ icons.mdiCloudUploadOutline }}
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t("upload_pictures") }}</span>
                </v-tooltip>

                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      color="error"
                      class="ma-2 white--text"
                      v-bind="attrs"
                      x-small
                      fab
                      @click="deleteImage(6)"
                      v-on="on"
                    >
                      <v-icon dark>
                        {{ icons.mdiDeleteOutline }}
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t("DeleteImage") }}</span>
                </v-tooltip>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { quillEditor } from 'vue-quill-editor'
import { mdiDeleteOutline, mdiCloudUploadOutline } from '@mdi/js'
import useSystemSetting from './useSystemSetting'

export default {
  components: {
    quillEditor,
  },

  setup() {
    const {
      shopData,
      bankData,
      bankSelect,
      shop_image,
      shopDetail,
      blob_image,
      loading,
      selectImage,
      showSelectImage,
      getShop,
      previewFiles,
      deleteImage,
      uploadImage,
      updateShop,

    } = useSystemSetting()

    return {
      shopData,
      bankData,
      bankSelect,
      shop_image,
      blob_image,
      loading,
      shopDetail,
      selectImage,
      showSelectImage,
      getShop,
      previewFiles,
      deleteImage,
      uploadImage,
      updateShop,
      icons: {
        mdiDeleteOutline, mdiCloudUploadOutline,
      },
    }
  },

}
</script>

<style lang="scss" scoped>

</style>
<style lang="scss">

.ql-toolbar.ql-snow {
    border: 1px solid #ccc;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    box-sizing: border-box;
    font-family: 'Kanit', sans-serif;
    padding: 8px;
}

.ql-container {
  box-sizing: border-box;
  font-family: 'Kanit', sans-serif;
  font-size: 16px;
  height: 215px;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  margin: 0px;
  position: relative;
}

</style>
