import shop from '@/api/shop'
import bankicon from '@/api/WebsiteManageMent/bankicon'
import setting from '@/api/WebsiteManageMent/setting'
import store from '@/store'
import { onMounted, ref } from '@vue/composition-api'
import refreshToken from '@/plugins/refresh-token'

export default function useSystemSetting() {
  const loading = ref(false)
  const shopData = ref({})
  const bankData = ref([])
  const bankSelect = ref('')
  const shop_image = ref('')
  const blob_image = ref('')
  const shopDetail = ref('')
  const statusImageId = ref('')
  const selectImage = ref(null)
  const { shop: shopGet } = shop
  const { bankiconList } = bankicon
  const { settingUpdate, settingImageUpload, settingImageRemove } = setting

  onMounted(() => {
    refreshToken()
    getShop()
  })

  const getShop = () => {
    loading.value = true
    shopGet().then(res => {
      shopData.value = res
      bankiconList().then(data => {
        bankData.value = data
        bankSelect.value = data.find(item => item.bank_icon_id == res.bank_icon_id) || {
          bank_icon_id: '1',
          bank_icon: 'Promptpay',
        }
        shopDetail.value = res.shop_detail
        loading.value = false
      })
    })
  }

  const previewFiles = event => {
    shop_image.value = event.target.files[event.target.files.length - 1]
    blob_image.value = URL.createObjectURL(event.target.files[event.target.files.length - 1])
    uploadImage()
  }

  const showSelectImage = id => {
    statusImageId.value = id
    selectImage.value.click()
  }

  const deleteImage = id => {
    settingImageRemove(id).then(res => {
      store.commit('app/ALERT', {
        message: res.message,
        color: res.response ? 'success' : 'error',
      })
      getShop()
    })
  }
  const uploadImage = () => {
    const formData = new FormData()
    formData.append('image', shop_image.value)
    formData.append('image_id', statusImageId.value)
    settingImageUpload(formData).then(res => {
      store.commit('app/ALERT', {
        message: res.message,
        color: res.response ? 'success' : 'error',
      })
      getShop()
    })
  }
  const updateShop = () => {
    loading.value = true
    settingUpdate({
      shop_detail: shopDetail.value,
      bank_icon_id: bankSelect.value.bank_icon_id,
      bank_name: shopData.value.bank_name,
      bank_branch: shopData.value.bank_branch,
      bank_account_name: shopData.value.bank_account_name,
      bank_account_number: shopData.value.bank_account_number,
    }).then(res => {
      store.commit('app/ALERT', {
        message: res.message,
        color: res.response ? 'success' : 'error',
      })
      getShop()
      loading.value = false
    })
  }

  return {
    shopData,
    bankData,
    bankSelect,
    shop_image,
    blob_image,
    loading,
    shopDetail,
    selectImage,
    showSelectImage,
    getShop,
    previewFiles,
    deleteImage,
    uploadImage,
    updateShop,
  }
}
